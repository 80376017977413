// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
    $color,
    $bg-start,
    $bg-end,
) {
  color: $color;
  @include gradient-y($bg-start, $bg-end);

    @include hover {
      color: $color;
      @include gradient-y($bg-end, $bg-end);
    }
}

@mixin button-outline-variant(
  $color,
  $bg,
  $border,
  $hover-color
) {
  color: $color;
  background-color: $bg;
  background-image: none;
  border: $btn-border-width solid $border;

  &:hover {
    color: $hover-color;
    background-color: rgba($border, .8);
  }
}

// Button sizes
@mixin button-size($height, $padding-x, $font-size, $line-height, $border-radius) {
  height: $height;
  padding: 0 $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}
