.gallery {

    // .owl-stage {
    //   @extend .mx-auto;
    // }

    .col-6 {

        @include media-breakpoint-down(xs) {

            &:not(:nth-child(-n + 4)) {
              display: none;
            }
        }
    }

    &__box {
      height: 0;
      padding-bottom: 100%;
      border: 1px solid $white;

        img  {
          @include element-center(true, 0, -9999px, 0, -9999px);
          min-width: 100%;
          max-width: 130%;
          height: 100%;
          width: auto;
          transition: $default-transition;
        }

        a:hover {
        
            img {
              opacity: .5;
            }

            &:after {
              content: '';
              @include element-center(true);
              @extend .z-index-2;
              @include size(60px);
              background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3e%3cpath fill='#{$white}' d='M59.629,59.634 C59.385,59.878 59.065,60.000 58.745,60.000 C58.425,60.000 58.106,59.878 57.862,59.634 L39.607,41.378 C35.397,45.170 29.846,47.500 23.748,47.500 C10.653,47.500 -0.000,36.846 -0.000,23.750 C-0.000,10.654 10.653,0.000 23.748,0.000 C36.843,0.000 47.496,10.654 47.496,23.750 C47.496,29.849 45.166,35.400 41.374,39.610 L59.629,57.866 C60.117,58.354 60.117,59.145 59.629,59.634 ZM44.996,23.750 C44.996,12.032 35.463,2.500 23.748,2.500 C12.033,2.500 2.500,12.033 2.500,23.750 C2.500,35.467 12.033,45.000 23.748,45.000 C35.463,45.000 44.996,35.468 44.996,23.750 ZM25.000,34.000 L23.000,34.000 L23.000,25.000 L14.000,25.000 L14.000,23.000 L23.000,23.000 L23.000,14.000 L25.000,14.000 L25.000,23.000 L34.000,23.000 L34.000,25.000 L25.000,25.000 L25.000,34.000 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat center/60px 60px;
            }
        }
    }

    &__filter {

        &:after {
          content: '';
          @include element-center(true, 0, 0, 0, false);
          width: 30px;
          @include gradient-x(transparent, $white, 0, 70%);
        }
      
        button {
          height: 30px;

            @include media-breakpoint-up(sm) {
                
                &:hover {
                  color: $white;
                  background-color: $yellow;
                }
            }

            
            &.current {
              color: $white;
              background-color: $green;
            }
        }
    }
}