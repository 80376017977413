/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  @extend .mw-100;
  -webkit-tap-highlight-color: transparent;
  @extend .position-relative;
  z-index: 1;

    &.owl-drag .owl-item {
      touch-action: pan-y;
      user-select: none;
    }

    &.owl-grab {
      cursor: move;
      cursor: grab;
    }
}

.owl-stage {
  @extend .position-relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  backface-visibility: hidden;
}

.owl-stage:after {
  content: ".";
  @extend .d-block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-stage-outer {
  @extend .position-relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-wrapper,
.owl-item{
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
}

.owl-item {
  @extend .position-relative;
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-nav.disabled,
.owl-dots.disabled {
  @extend .d-none;
}

.owl-prev,
.owl-next,
.owl-dot {
  cursor: pointer;
  user-select: none;
}

.owl-dots {
  @extend .mt-10, .text-center;

    @include media-breakpoint-up(sm) {
      @include element-center(false, false, 0, rem(40));
    }
}

.owl-dot {
  transform: rotate(45deg);

    @include media-breakpoint-up(sm) {
      @include size(15px);
    }

    @include media-breakpoint-down(xs) {
      @include size(10px);
    }

    &:not(:last-child) {
      @extend .mr-20;
    }
    
    &:not(:hover):not(.active) {
      opacity: .7;
    }
    
    &:not(.active) {
      @include gradient-directional($yellow-start, $yellow-end, 180deg);
    }

    &.active {
      @include gradient-directional($green-start, $green-end, 180deg);
    }
}

.owl-prev,
.owl-next {
  @extend .position-absolute;
  top: 0;
  bottom: 47px;
  @include size(60px);
  border: 2px solid $white;
  @extend .m-auto, .rounded-circle, .d-md-block, .d-none;

    &:before,
    &:after {
      content: '';
      @include element-center(true);
      @include size(16px, 2px);
      @extend .bg-white, .rounded;
    }

    &:hover {
      background-color: rgba($yellow, .3);
      border-color: $yellow;
    }
}

.owl-prev {
  left: $grid-gutter-width / 2;

    &:before {
      transform: translate(-1px, 5px) rotate(45deg);
    }

    &:after {
      transform: translate(-1px, -5px) rotate(-45deg);
    }
}

.owl-next {
  right: $grid-gutter-width / 2;

    &:before {
      transform: translate(1px, -5px) rotate(45deg);
    }

    &:after {
      transform: translate(1px, 5px) rotate(-45deg);
    }
}

.no-js .owl-carousel {
	@extend .d-block;
}

 .owl-height {
	transition: height 500ms ease-in-out;
}
