.crops {
  background: url('#{$images-background}helga-crops-bg.svg') calc(50% - 30px) center no-repeat ;

    &__box {
      transition: $default-transition;
      box-shadow: $box-shadow;

        @include media-breakpoint-up(sm) {

          &:hover {
              box-shadow: $box-shadow-lg;
              transform: translate(0, -15px);
            }
        }

        @include media-breakpoint-down(xs) {

            img {
              min-width: 100%;
            }
        }
    }
}