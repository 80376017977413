@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 45 Light'), local('HelveticaNeueLTStd-Lt'),
      url('#{$font-path}HelveticaNeueLTStd-Lt.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-Lt.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 46 Light Italic'), local('HelveticaNeueLTStd-LtIt'),
      url('#{$font-path}HelveticaNeueLTStd-LtIt.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-LtIt.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-LtIt.svg#HelveticaNeueLTStd-LtIt') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 55 Roman'), local('HelveticaNeueLTStd-Roman'),
      url('#{$font-path}HelveticaNeueLTStd-Roman.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-Roman.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 56 Italic'), local('HelveticaNeueLTStd-It'),
      url('#{$font-path}HelveticaNeueLTStd-It.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-It.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-It.svg#HelveticaNeueLTStd-It') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 65 Medium'), local('HelveticaNeueLTStd-Md'),
      url('#{$font-path}HelveticaNeueLTStd-Md.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-Md.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 66 Medium Italic'), local('HelveticaNeueLTStd-MdIt'),
      url('#{$font-path}HelveticaNeueLTStd-MdIt.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-MdIt.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-MdIt.svg#HelveticaNeueLTStd-MdIt') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 75 Bold'), local('HelveticaNeueLTStd-Bd'),
      url('#{$font-path}HelveticaNeueLTStd-Bd.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-Bd.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local('Helvetica Neue LT Std 76 Bold Italic'), local('HelveticaNeueLTStd-BdIt'),
      url('#{$font-path}HelveticaNeueLTStd-BdIt.woff2') format('woff2'),
      url('#{$font-path}HelveticaNeueLTStd-BdIt.woff') format('woff'),
      url('#{$font-path}HelveticaNeueLTStd-BdIt.svg#HelveticaNeueLTStd-BdIt') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
