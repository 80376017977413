// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius, 0);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    padding-top: 0px;
    color: $input-placeholder-color;
    font-weight: 300;
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 7px rgba($input-border-color, .65);
  }

  // &:disabled,
  // &[readonly] {
  //   background-color: $input-disabled-bg;
  //   opacity: 1;
  // }
}

select.form-control {
  &:focus::-ms-value {
    color: $input-color;
    background-color: $input-bg;
  }
}

textarea.form-control {
  height: 264px;
}

// Form groups
//
.form-group {
  margin-bottom: $form-group-margin-bottom;
}