// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//
$heading-sizes: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size, $h5-font-size, $h6-font-size;

// h1, .h1 { font-size: $h1-font-size; }
// h2, .h2 { font-size: $h2-font-size; }
// h3, .h3 { font-size: $h3-font-size; }
// h4, .h4 { font-size: $h4-font-size; }
// h5, .h5 { font-size: $h5-font-size; }
// h6, .h6 { font-size: $h6-font-size; }

@for $i from 1 to 7 {
  h#{$i}, .h#{$i} {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  h#{$i}, .h#{$i} {
    font-size: nth($heading-sizes, $i)
  }

  // @each $breakpoint in map-keys($grid-breakpoints) {
  //   @include media-breakpoint-up($breakpoint) {
  //     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  //     .h#{$i}#{$infix} {
  //       font-size: nth($heading-sizes, $i)
  //     }
  //   }
  // }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .font-size-base#{$infix} {
      font-size: $font-size-base !important;
    }

    .font-size-lg#{$infix} {
      font-size: $font-size-lg !important;
    }

    .font-size-sm#{$infix} {
      font-size: $font-size-sm !important;
    }
  }
}


.line-height-0 {
	line-height: 0 !important;
}

.line-height-base {
	line-height: $line-height-base !important;
}