@for $i from 1 through 5 {
  .z-index-#{$i} {
    z-index: #{$i}
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

.w-xs-100 {
  @include media-breakpoint-down(xs) {
    width: 100% !important;
  }
}

.socials {
  font-size: 0;

    a {
      
        &:not(:hover) {
          opacity: .25;
        }
    }
}