$colors: map-remove(
  $colors,
  "blue",
  "indigo",
  "purple",
  "pink",
  "red",
  "orange",
  // "yellow",
  // "green",
  "teal",
  "cyan",
  // "white",
  "gray",
  "gray-dark"
);

$colors: map-merge((
  "black": $black,
  "gray-200": $gray-200,
  "gray-800": $gray-800
), $colors);

$theme-colors: map-remove(
	$theme-colors, 
  "secondary",
  "success",
  "primary",
  "info",
  "warning",
  "danger",
  "light",
  "dark"
);

$rfs-font-size-unit: px;
$enable-caret: false;
$enable-prefers-reduced-motion-media-query: false;
$enable-print-styles: false;
$enable-validation-icons: false;
$enable-deprecation-messages: false;

$spacers: map-remove(
  $spacers, 1, 2, 3, 4, 5
);

$spacers: map-merge((
  grid-half: $grid-gutter-width / 2,
  grid: $grid-gutter-width,
  5: 5px,
  8: 8px,
  10: 10px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  35: rem(35px),
  40: rem(40px),
  45: rem(45px),
  50: rem(50px),
  60: rem(60px),
  70: rem(70px),
), $spacers);

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1230px,
);

$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1200px
);

$body-bg: $white;
$body-color: $gray-700;

$link-color: inherit;
$link-decoration: none;
$link-hover-color: inherit;
$link-hover-decoration: none;

$line-height-base: 1;
$line-height-sm: 1;
$line-height-lg: 1;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Helvetica Neue LT Std', sans-serif;
$font-family-base: $font-family-sans-serif;

$font-weight-medium: 500;
$font-weight-base: 300;

$font-size-base: 15px;
$font-size-lg: 18px;
$font-size-sm: 14px;

$headings-margin-bottom: 0;
$headings-font-family: Arial, sans-serif;
$headings-line-height: 1.1;
$headings-font-weight: $font-weight-bold;
$headings-color: $gray-800;

$h1-font-size: rem(42px);
$h2-font-size: rem(32px);
$h3-font-size: rem(28px);
$h4-font-size: rem(20px);
$h5-font-size: rem($font-size-lg);
$h6-font-size: $font-size-base;

$paragraph-margin-bottom: 0;

$btn-border-width: 2px;
$btn-font-weight: 700;
$btn-height: 50px;
$btn-font-size: 16px;

$btn-height-sm: 40px;

$border-radius: 4px;
$border-radius-sm: 4px;
$border-radius-lg: 8px;

$input-height: 50px;
$input-padding-y: 15px;
$input-padding-x: 15px;
$input-line-height: 1;
$input-color: $gray-800;
$input-bg: $white;
$input-border-color: $green;
$input-border-radius: $border-radius;
$input-placeholder-color: #999999;

$form-group-margin-bottom: 30px;

$box-shadow: 0 2px 17px rgba($black, .16);