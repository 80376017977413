
// Color system
$white:    #fff;
$gray-100: #F9F9F9;
$gray-200: #F8F8F8;
$gray-300: #ECEFF2;
$gray-400: #D5D6D7;
$gray-500: #ACAEB0;
$gray-600: #A7A7A7;
$gray-700: #666666;
$gray-800: #333333;
$gray-900: #111111;
$black:    #000;

// Theme Colors
$green: #6ABD45;
$yellow: #FFDA01;

$yellow-start: #FFDA01;
$yellow-end: #FFA43C;
$green-start: #beef01;
$green-end: #6ABD45;

// Typography

// Buttons
$btn-colors: (
	"black": (
		color: $white,
    bg-start: $gray-700,
    bg-end: $black,
  ),
	"green": (
		color: $white,
    bg-start: $green-start,
    bg-end: $green-end
  ),
  "yellow": (
		color: $white,
    bg-start: $yellow-start,
    bg-end: $yellow-end,
  )
);

$btn-outline-colors: (
  "yellow": (
    color: $white,
    bg: rgba($yellow, .5),
    border: $yellow,
    hover-color: $white,
  )
);