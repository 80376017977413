.main-menu {

    a {
      @extend .d-block, .p-sm-10, .py-15, .px-md-15;

        &:hover {
          color: $yellow;
        }

    }

    > li {
    
        &:not(:last-child) {
          @extend .mr-md-15;

            @include media-breakpoint-down(xs) {
              border-bottom: 1px solid rgba($black, .05)
            }
        }

        > a {
          @extend .py-sm-20, .text-uppercase;
        }
    }
    
    .menu-item-has-children {
      @extend .position-relative;

        @include media-breakpoint-up(sm) {
          
            &:hover {
              
                > .sub-menu {
                  display: block;
                }
            }
        }

        @include media-breakpoint-down(xs) {

            &.sub-menu-opened {
                
                > .sub-menu {
                  display: block;
                }
            }
        }
      
        > a {
          @extend .d-flex, .align-items-center;

            &:after {
              content: '';
              @include size(0);
              @extend .ml-sm-8, .ml-auto;
              border-top: 4px solid $green;
              border-right: 4px solid transparent;
              border-left: 4px solid transparent;
              transition: $default-transition;
            }

            &:hover:after {
              border-top-color: $yellow;
            }
        }
    }

    .sub-menu {
      display: none;

        @include media-breakpoint-up(sm) {
          @include element-center(false, 100%, false, false);
          padding: 5px 0;
          z-index: 2;
          white-space: nowrap;
          background: $white;
          @include border-bottom-radius($border-radius);
          box-shadow: 0 10px 10px rgba($black, .05);
        }
    }
}

#menu {

    @include media-breakpoint-down(xs) {
      transition: none !important;
      @include fixed-center(false, 56px, 0, false, 0);
      overflow: auto;
      bottom: 0;
      background-color: $white;
    }
}