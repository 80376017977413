//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	color: $white;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	white-space: nowrap;
	background: transparent;
	min-width: 150px;
	text-shadow: 0 1px 1px rgba($black, .12);
	// border: $btn-border-width solid transparent;
	@include button-size($btn-height, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

		@include media-breakpoint-down(xs) {
			height: $btn-height - 5px;
		}
	
		@at-root :not(button)#{&} {
			@extend .d-inline-flex;
			@extend .align-items-center;
			@extend .justify-content-center;
			cursor: pointer;
		}

		&--sm {
			@include button-size($btn-height-sm, $btn-padding-x, $btn-font-size, $line-height-base, $btn-border-radius);

				@include media-breakpoint-down(xs) {
					height: $btn-height-sm - 5px;
				}
		}

		&--200 {

				@include media-breakpoint-up(sm) {
					min-width: 200px;
				}
		}

		&--play {
			top: 0;
			left: 0;
			font-size: 22px;

				&:hover {
					background-color: rgba($green, .2);
				}
		}

		&-menu {
			@include button-menu(30px, 30px, 4px, 8px, $green, $yellow, 2px);
		}

		// &__state {

		// 		&__default {

		// 				.btn__state[aria-expanded="true"] &,
		// 				.btn__state.active:not([aria-expanded]) & {
		// 				// input:checked ~ .btn__state & {
		// 					@extend .d-none;
		// 				}
		// 		}

		// 		&__active {
						
		// 				.btn__state[aria-expanded="false"] &,
		// 				.btn__state:not(.active):not([aria-expanded]) & {
		// 					@extend .d-none;
		// 				}
		// 		}
		// }
}

//
// Alternate buttons
//
@each $color, $value in $btn-colors {
	$text-color: map-get($value, color);
	$bg-start: map-get($value, bg-start);
	$bg-end: map-get($value, bg-end);

		.btn--#{$color} {
			@include button-variant(
				$text-color,
				$bg-start,
				$bg-end
			);
		}
}

@each $color, $value in $btn-outline-colors {
	$text-color: map-get($value, color);
	$bg: map-get($value, bg);
	$border: map-get($value, border);
	$hover-color: map-get($value, hover-color);

		.btn--outline-#{$color} {
			@include button-outline-variant(
				$text-color,
				$bg,
				$border,
				$hover-color
			);
		}
}
