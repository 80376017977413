.footer {
  background-color: $gray-900;

    &__menu {
      line-height: (26 / 16);

        a:hover {
          color: $green;
          text-decoration: underline;
        }
    }
}

.copy {
  color: #666;
  border-top: 1px solid rgba($white, .1);
}