@each $color, $value in $colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

.text-inherit {
	color: inherit !important;
}

.text-shadow { text-shadow: 0 1px 1px rgba($black, .17) !important; }

.font-weight-medium { font-weight: $font-weight-medium !important; }

p {
	line-height: (26 / 16);

		> a {
			color: $green;

				&:hover {
					color: $green;
					text-decoration: underline;
				}
		}
}

img.aligncenter {
	@extend .d-block, .mx-auto;
}

.entry-content {
	line-height: (26 / 16);

		h1, h2, h3, h4, h5, h6,
		p, ul, ol {

				&:not(:last-child) {
					@extend .mb-20;
				}
		}

		h1, h2, h3, h4, h5, h6 {
			@extend .text-green;
		}

		ul {
			@extend .font-weight-bold;

				li {
					margin-bottom: 10px;
					padding-left: 35px;
					background: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 20 20'%3e%3cpath fill='#{$green}' d='M10,3 C4.48577883,3 0,7.48577883 0,13 C0,18.5142212 4.48577883,23 10,23 C15.5142212,23 20,18.5142212 20,13 C20,7.48577883 15.5142212,3 10,3 Z M15.0683594,10.8808594 L9.65164184,16.2974243 C9.48913574,16.4599304 9.27581789,16.5417175 9.0625,16.5417175 C8.84918211,16.5417175 8.63586426,16.4599304 8.47335816,16.2974243 L5.7650757,13.5891418 C5.43914797,13.2633667 5.43914797,12.7366333 5.7650757,12.4108582 C6.09085082,12.0849304 6.61743164,12.0849304 6.94335937,12.4108582 L9.0625,14.5299988 L13.8900757,9.7025757 C14.2158508,9.37664797 14.7424316,9.37664797 15.0683594,9.7025757 C15.3941345,10.0283508 15.3941345,10.5549316 15.0683594,10.8808594 Z'/%3e%3c/svg%3e"), "#", "%23") no-repeat 0 0/20px 20px;
				}
		}

		img {
			@extend .rounded-lg;
			box-shadow: 0 4px 12px rgba($black, .19);
		}
}

@include  media-breakpoint-only(md) {

		html {
			font-size: 13px;
		}

		body {
			font-size: 15px;
		}
}

@include  media-breakpoint-only(sm) {

	html {
		font-size: 11px;
	}

	body {
		font-size: 14px;
	}
}

@include  media-breakpoint-down(xs) {

	html {
		font-size: 9px;
	}

	body {
		font-size: 13px;
	}
}