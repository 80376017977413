.services {

    &__box {
      height: 0;
      color: $white;
      padding-bottom: percentage(500 / 640);
      border: 1px solid $white;
      background: no-repeat center/cover;

        &:after {
          content: '';
          @include element-center(true, false);
          height: 150px;
          @include gradient-y(transparent, rgba($black, .8));
          transition: $default-transition;
        }

        &:hover {
          color: $yellow;

            &:after {
              height: 200px;
            }
        }

        @include media-breakpoint-up(sm) {

            &--lg {
              padding-bottom: percentage(500 / 960);
            }
        }


        &__content {
          top: 0;
          left: 0;
        }
    }
}