body.lb-disable-scrolling {
  @extend .overflow-hidden;
}

.lightboxOverlay {
  @extend .position-absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  opacity: .65;
  display: none;
}

.lightbox {
  @extend .position-absolute;
  left: 0;
  @extend .w-100;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  @extend .rounded-lg;
  box-shadow: 0 4px 36px rgba($black, .29);
}


.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  @extend .rounded-lg, .mx-auto, .bg-white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  @extend .d-none;
}

.lb-prev, 
.lb-next {
  @include element-center(true, 0, false, 0, false);
  @include size(40px);
  @extend .rounded-circle;
  border: 2px solid $white;

    &:before,
    &:after {
      content: '';
      @include  element-center(true);
      @include size(10px, 2px);
      @extend .rounded-pill;
      transition: $default-transition;
      background-color: $white;
    }

    @include media-breakpoint-up(sm) {

        &:hover {
          border-color: $green;
    
            &:before,
            &:after {
              background-color: $green;
            }
        }
    }

}

.lb-prev {
  @extend .mr-md-15, .ml-5;
  
    @include media-breakpoint-up(md) {
      right: 100%;
    }

    @include media-breakpoint-down(sm) {
      left: 0;
    }

    &:before {
      transform: translate(0, 3px) rotate(45deg);
    }

    &:after {
      transform: translate(0, -3px) rotate(-45deg);
    }
}

.lb-next {
  @extend .ml-md-15, .mr-5;

  @include media-breakpoint-up(md) {
    left: 100%;
  }

  @include media-breakpoint-down(sm) {
    right: 0;
  }

  &:before {
    transform: translate(0, -3px) rotate(45deg);
  }

  &:after {
    transform: translate(0, 3px) rotate(-45deg);
  }
}

.lb-dataContainer {
  @include element-center(true, false, 0, 100%);
  @extend .mx-auto, .text-right;
  *zoom: 1;
  width: 100%;
  opacity: 1 !important;
}

.lb-details {
  @extend .d-none;
}

.lb-close {
  @extend .position-relative, .d-inline-block;
  @include size(30px);

    &:before,
    &:after {
      content: '';
      @include element-center(true);
      @include size(18px, 2px);
      @extend .rounded-pill;
    }

    &:not(:hover) {

        &:before,
        &:after {
          @extend .bg-white;
        }
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover {

        &:before,
        &:after {
          background-color: $green;
        }
    }
}
