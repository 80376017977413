.offices {

    &__btn {
      height: rem(60px);
      font-size: rem(20px);
      margin-bottom: -2px;

        @include media-breakpoint-up(sm) {
          width: rem(240px);
        }

        &:first-child {
          @include border-top-left-radius($border-radius-lg);

            &:not(:last-child).active {
              box-shadow: inset 0 2px 0 $green, inset 2px 0 0 $green, 0 -5px 20px rgba($green, .18);
            }

            &:last-child.active {
              box-shadow: inset 0 2px 0 $green, inset 2px 0 0 $green, inset -2px 0 0 $green, 0 -5px 20px rgba($green, .18);
            }
        }

        &:last-child {
          @include border-top-right-radius($border-radius-lg);

            &:not(:first-child).active {
              box-shadow: inset 0 2px 0 $green, inset -2px 0 0 $green, 0 -5px 20px rgba($green, .18);
            }
        }

        &:not(.active) {
          @extend .text-white;
          @extend .bg-green;
        }

        &.active {
          @extend .text-gray-800;
          background-color: $white;
          box-shadow: inset 0 2px 0 $green, 0 -5px 20px rgba($green, .18);
        }
    }

    &__content {
      border: 2px solid $green;
      @include border-bottom-radius($border-radius-lg);

        @include media-breakpoint-up(sm) {
          @include border-top-right-radius($border-radius-lg);
        }

        a {
          color: inherit;
        }
    }
}