.slider {

    &:not(.owl-loaded) {
      display: flex;
      overflow: hidden;
    }

    .item {

        :not(.owl-loaded) & {
          max-width: 100%;
          flex: 0 0 100%;

            &:not(:first-child) {
              @extend .d-none;
            }
        }

        // @include media-breakpoint-up(sm) {
        //   background: no-repeat center/cover;
        // }
        
        @include media-breakpoint-up(md) {
          min-height: 500px;
        }

        @include media-breakpoint-only(sm) {
          min-height: 300px;
        }

        img[src*="tractor"] {
          
            @include media-breakpoint-up(md) {
              max-width: none;
              position: relative;
              bottom: -26px;
              left: -25%;
            }

            @include media-breakpoint-up(sm) {
              max-width: 180%;
              position: relative;
              bottom: -26px;
              left: -25%;
            }
        }
    }

    &__bg {

      img {
          @include element-center(true, 0, -9999px, 0, -9999px);
          height: 100%;
          width: auto;
          max-width: none;
        }
    }
}