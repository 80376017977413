html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {

		a {
			display: block;
		}
}

input,
button,
select,
optgroup,
textarea {
	padding: 0;
	border: none;
	background: none;
	appearance: none;

		&:focus {
			outline: none;
		}
}

figure,
blockquote {
	margin: 0;
}

img {
	display: inline-block;
	@include img-fluid();
}

address, ol, ul, dl, pre {
	margin-bottom: 0;
}

ul,
ol {
	@include list-unstyled();
}

a,
button,
input[type="submit"],
input[type="reset"] {
	cursor: pointer;
	transition: $default-transition;

		&:focus {
			outline: none !important;
		}
}

button {
	color: $link-color;
	text-decoration: $link-decoration;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;

		@include hover {
			color: $link-hover-color;
		}
}

strong, b {
	font-weight: $font-weight-bold;
}

p {

		a {

				&:hover {
					text-decoration: underline;
				}
		}
}

.mw-none {
	max-width: none !important;

		img {
			max-width: none !important;
		}
}