@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 45 Light"), local("HelveticaNeueLTStd-Lt"), url("../fonts/HelveticaNeueLTStd-Lt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Lt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 46 Light Italic"), local("HelveticaNeueLTStd-LtIt"), url("../fonts/HelveticaNeueLTStd-LtIt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-LtIt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-LtIt.svg#HelveticaNeueLTStd-LtIt") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 55 Roman"), local("HelveticaNeueLTStd-Roman"), url("../fonts/HelveticaNeueLTStd-Roman.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Roman.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 56 Italic"), local("HelveticaNeueLTStd-It"), url("../fonts/HelveticaNeueLTStd-It.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-It.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-It.svg#HelveticaNeueLTStd-It") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 65 Medium"), local("HelveticaNeueLTStd-Md"), url("../fonts/HelveticaNeueLTStd-Md.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Md.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 66 Medium Italic"), local("HelveticaNeueLTStd-MdIt"), url("../fonts/HelveticaNeueLTStd-MdIt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-MdIt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-MdIt.svg#HelveticaNeueLTStd-MdIt") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 75 Bold"), local("HelveticaNeueLTStd-Bd"), url("../fonts/HelveticaNeueLTStd-Bd.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-Bd.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue LT Std';
  src: local("Helvetica Neue LT Std 76 Bold Italic"), local("HelveticaNeueLTStd-BdIt"), url("../fonts/HelveticaNeueLTStd-BdIt.woff2") format("woff2"), url("../fonts/HelveticaNeueLTStd-BdIt.woff") format("woff"), url("../fonts/HelveticaNeueLTStd-BdIt.svg#HelveticaNeueLTStd-BdIt") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue LT Std", sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 1;
  color: #666666;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: inherit;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #A7A7A7;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  display: block; }

input,
button,
select,
optgroup,
textarea {
  padding: 0;
  border: none;
  background: none;
  appearance: none; }
  input:focus,
  button:focus,
  select:focus,
  optgroup:focus,
  textarea:focus {
    outline: none; }

figure,
blockquote {
  margin: 0; }

img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

address, ol, ul, dl, pre {
  margin-bottom: 0; }

ul,
ol {
  padding-left: 0;
  list-style: none; }

a,
button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  transition: 250ms ease-in-out; }
  a:focus,
  button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus {
    outline: none !important; }

button {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  button:hover {
    color: inherit; }

strong, b {
  font-weight: 700; }

p a:hover {
  text-decoration: underline; }

.mw-none {
  max-width: none !important; }
  .mw-none img {
    max-width: none !important; }

.z-index-1 {
  z-index: 1; }

.z-index-2, .gallery__box a:hover:after {
  z-index: 2; }

.z-index-3 {
  z-index: 3; }

.z-index-4 {
  z-index: 4; }

.z-index-5 {
  z-index: 5; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

@media (max-width: 767.98px) {
  .w-xs-100 {
    width: 100% !important; } }

.socials {
  font-size: 0; }
  .socials a:not(:hover) {
    opacity: .25; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-gray-200 {
  background-color: #F8F8F8 !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #dfdede !important; }

.bg-gray-800 {
  background-color: #333333 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #1a1919 !important; }

.bg-yellow {
  background-color: #FFDA01 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #cdaf00 !important; }

.bg-green, .offices__btn:not(.active) {
  background-color: #6ABD45 !important; }

a.bg-green:hover, a.offices__btn:hover:not(.active), a.bg-green:focus, a.offices__btn:focus:not(.active),
button.bg-green:hover,
button.offices__btn:hover:not(.active),
button.bg-green:focus,
button.offices__btn:focus:not(.active) {
  background-color: #559936 !important; }

.bg-white, .owl-prev:before, .owl-prev:after,
.owl-next:before,
.owl-next:after, .lb-outerContainer, .lb-close:not(:hover):before, .lb-close:not(:hover):after {
  background-color: #fff !important; }

a.bg-white:hover, a.owl-prev:hover:before, a.owl-prev:hover:after,
a.owl-next:hover:before,
a.owl-next:hover:after, a.lb-outerContainer:hover, a.lb-close:hover:not(:hover):before, a.lb-close:hover:not(:hover):after, a.bg-white:focus, a.owl-prev:focus:before, a.owl-prev:focus:after,
a.owl-next:focus:before,
a.owl-next:focus:after, a.lb-outerContainer:focus, a.lb-close:focus:not(:hover):before, a.lb-close:focus:not(:hover):after,
button.bg-white:hover,
button.owl-prev:hover:before,
button.owl-prev:hover:after,
button.owl-next:hover:before,
button.owl-next:hover:after,
button.lb-outerContainer:hover,
button.lb-close:hover:not(:hover):before,
button.lb-close:hover:not(:hover):after,
button.bg-white:focus,
button.owl-prev:focus:before,
button.owl-prev:focus:after,
button.owl-next:focus:before,
button.owl-next:focus:after,
button.lb-outerContainer:focus,
button.lb-close:focus:not(:hover):before,
button.lb-close:focus:not(:hover):after {
  background-color: #e6e5e5 !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1230px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.3333333333%; }
  .offset-sm-2 {
    margin-left: 16.6666666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.3333333333%; }
  .offset-sm-5 {
    margin-left: 41.6666666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.3333333333%; }
  .offset-sm-8 {
    margin-left: 66.6666666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.3333333333%; }
  .offset-sm-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.3333333333%; }
  .offset-md-2 {
    margin-left: 16.6666666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.3333333333%; }
  .offset-md-5 {
    margin-left: 41.6666666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.3333333333%; }
  .offset-md-8 {
    margin-left: 66.6666666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.3333333333%; }
  .offset-md-11 {
    margin-left: 91.6666666667%; } }

@media (min-width: 1230px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.3333333333%; }
  .offset-lg-2 {
    margin-left: 16.6666666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.3333333333%; }
  .offset-lg-5 {
    margin-left: 41.6666666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.3333333333%; }
  .offset-lg-8 {
    margin-left: 66.6666666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.3333333333%; }
  .offset-lg-11 {
    margin-left: 91.6666666667%; } }

.fade {
  transition: opacity 0.15s linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-white, .owl-prev:before, .owl-prev:after,
.owl-next:before,
.owl-next:after, .lb-outerContainer, .lb-close:not(:hover):before, .lb-close:not(:hover):after {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ECEFF2 !important; }

.border-top {
  border-top: 1px solid #ECEFF2 !important; }

.border-right {
  border-right: 1px solid #ECEFF2 !important; }

.border-bottom {
  border-bottom: 1px solid #ECEFF2 !important; }

.border-left {
  border-left: 1px solid #ECEFF2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 4px !important; }

.rounded, .owl-prev:before, .owl-prev:after,
.owl-next:before,
.owl-next:after {
  border-radius: 4px !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-lg, .lightbox .lb-image, .lb-outerContainer, .entry-content img {
  border-radius: 8px !important; }

.rounded-circle, .owl-prev,
.owl-next, .lb-prev,
.lb-next {
  border-radius: 50% !important; }

.rounded-pill, .lb-prev:before, .lb-prev:after,
.lb-next:before,
.lb-next:after, .lb-close:before, .lb-close:after {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none, .owl-nav.disabled,
.owl-dots.disabled, .owl-prev,
.owl-next, .lb-loader, .lb-details, :not(.owl-loaded) .slider .item:not(:first-child) {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block, .lb-close {
  display: inline-block !important; }

.d-block, .owl-stage:after, .no-js .owl-carousel, img.aligncenter, .main-menu a {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .main-menu .menu-item-has-children > a {
  display: flex !important; }

.d-inline-flex, .btn:not(button) {
  display: inline-flex !important; }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block, .owl-prev,
  .owl-next {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1230px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center, .btn:not(button) {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .btn:not(button), .main-menu .menu-item-has-children > a {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1230px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right, .order-md-2 .image-overlay {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1230px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden, body.lb-disable-scrolling {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative, .owl-carousel, .owl-stage, .owl-stage-outer, .owl-item, .lb-close, .main-menu .menu-item-has-children {
  position: relative !important; }

.position-absolute, .owl-prev,
.owl-next, .owl-prev:before, .owl-prev:after,
.owl-next:before,
.owl-next:after, .lightboxOverlay, .lightbox, .lb-prev,
.lb-next, .lb-prev:before, .lb-prev:after,
.lb-next:before,
.lb-next:after, .lb-dataContainer, .lb-close:before, .lb-close:after, .image-overlay:after, .slider__bg img, .services__box:after, .gallery__box img, .gallery__box a:hover:after, .gallery__filter:after {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 2px 17px rgba(0, 0, 0, 0.16) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100, .lightbox {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100, .owl-carousel {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-grid-half {
  margin: 15px !important; }

.mt-grid-half,
.my-grid-half {
  margin-top: 15px !important; }

.mr-grid-half,
.mx-grid-half {
  margin-right: 15px !important; }

.mb-grid-half,
.my-grid-half {
  margin-bottom: 15px !important; }

.ml-grid-half,
.mx-grid-half {
  margin-left: 15px !important; }

.m-grid {
  margin: 30px !important; }

.mt-grid,
.my-grid {
  margin-top: 30px !important; }

.mr-grid,
.mx-grid {
  margin-right: 30px !important; }

.mb-grid,
.my-grid {
  margin-bottom: 30px !important; }

.ml-grid,
.mx-grid {
  margin-left: 30px !important; }

.m-5 {
  margin: 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.lb-next,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5, .lb-prev,
.mx-5 {
  margin-left: 5px !important; }

.m-8 {
  margin: 8px !important; }

.mt-8,
.my-8 {
  margin-top: 8px !important; }

.mr-8,
.mx-8 {
  margin-right: 8px !important; }

.mb-8,
.my-8 {
  margin-bottom: 8px !important; }

.ml-8,
.mx-8 {
  margin-left: 8px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10, .owl-dots,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20, .owl-dot:not(:last-child),
.mx-20 {
  margin-right: 20px !important; }

.mb-20, .entry-content h1:not(:last-child), .entry-content h2:not(:last-child), .entry-content h3:not(:last-child), .entry-content h4:not(:last-child), .entry-content h5:not(:last-child), .entry-content h6:not(:last-child),
.entry-content p:not(:last-child), .entry-content ul:not(:last-child), .entry-content ol:not(:last-child),
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 2.1875rem !important; }

.mt-35,
.my-35 {
  margin-top: 2.1875rem !important; }

.mr-35,
.mx-35 {
  margin-right: 2.1875rem !important; }

.mb-35,
.my-35 {
  margin-bottom: 2.1875rem !important; }

.ml-35,
.mx-35 {
  margin-left: 2.1875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-45 {
  margin: 2.8125rem !important; }

.mt-45,
.my-45 {
  margin-top: 2.8125rem !important; }

.mr-45,
.mx-45 {
  margin-right: 2.8125rem !important; }

.mb-45,
.my-45 {
  margin-bottom: 2.8125rem !important; }

.ml-45,
.mx-45 {
  margin-left: 2.8125rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.m-70 {
  margin: 4.375rem !important; }

.mt-70,
.my-70 {
  margin-top: 4.375rem !important; }

.mr-70,
.mx-70 {
  margin-right: 4.375rem !important; }

.mb-70,
.my-70 {
  margin-bottom: 4.375rem !important; }

.ml-70,
.mx-70 {
  margin-left: 4.375rem !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.p-grid-half {
  padding: 15px !important; }

.pt-grid-half,
.py-grid-half {
  padding-top: 15px !important; }

.pr-grid-half,
.px-grid-half {
  padding-right: 15px !important; }

.pb-grid-half,
.py-grid-half {
  padding-bottom: 15px !important; }

.pl-grid-half,
.px-grid-half {
  padding-left: 15px !important; }

.p-grid {
  padding: 30px !important; }

.pt-grid,
.py-grid {
  padding-top: 30px !important; }

.pr-grid,
.px-grid {
  padding-right: 30px !important; }

.pb-grid,
.py-grid {
  padding-bottom: 30px !important; }

.pl-grid,
.px-grid {
  padding-left: 30px !important; }

.p-5 {
  padding: 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-8 {
  padding: 8px !important; }

.pt-8,
.py-8 {
  padding-top: 8px !important; }

.pr-8,
.px-8 {
  padding-right: 8px !important; }

.pb-8,
.py-8 {
  padding-bottom: 8px !important; }

.pl-8,
.px-8 {
  padding-left: 8px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15,
.main-menu a {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15,
.main-menu a {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 2.1875rem !important; }

.pt-35,
.py-35 {
  padding-top: 2.1875rem !important; }

.pr-35,
.px-35 {
  padding-right: 2.1875rem !important; }

.pb-35,
.py-35 {
  padding-bottom: 2.1875rem !important; }

.pl-35,
.px-35 {
  padding-left: 2.1875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-45 {
  padding: 2.8125rem !important; }

.pt-45,
.py-45 {
  padding-top: 2.8125rem !important; }

.pr-45,
.px-45 {
  padding-right: 2.8125rem !important; }

.pb-45,
.py-45 {
  padding-bottom: 2.8125rem !important; }

.pl-45,
.px-45 {
  padding-left: 2.8125rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.p-70 {
  padding: 4.375rem !important; }

.pt-70,
.py-70 {
  padding-top: 4.375rem !important; }

.pr-70,
.px-70 {
  padding-right: 4.375rem !important; }

.pb-70,
.py-70 {
  padding-bottom: 4.375rem !important; }

.pl-70,
.px-70 {
  padding-left: 4.375rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.m-ngrid-half {
  margin: -15px !important; }

.mt-ngrid-half,
.my-ngrid-half {
  margin-top: -15px !important; }

.mr-ngrid-half,
.mx-ngrid-half {
  margin-right: -15px !important; }

.mb-ngrid-half,
.my-ngrid-half {
  margin-bottom: -15px !important; }

.ml-ngrid-half,
.mx-ngrid-half {
  margin-left: -15px !important; }

.m-ngrid {
  margin: -30px !important; }

.mt-ngrid,
.my-ngrid {
  margin-top: -30px !important; }

.mr-ngrid,
.mx-ngrid {
  margin-right: -30px !important; }

.mb-ngrid,
.my-ngrid {
  margin-bottom: -30px !important; }

.ml-ngrid,
.mx-ngrid {
  margin-left: -30px !important; }

.m-n5 {
  margin: -5px !important; }

.mt-n5,
.my-n5 {
  margin-top: -5px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5px !important; }

.m-n8 {
  margin: -8px !important; }

.mt-n8,
.my-n8 {
  margin-top: -8px !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8px !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8px !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8px !important; }

.m-n10 {
  margin: -10px !important; }

.mt-n10,
.my-n10 {
  margin-top: -10px !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10px !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10px !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10px !important; }

.m-n15 {
  margin: -15px !important; }

.mt-n15,
.my-n15 {
  margin-top: -15px !important; }

.mr-n15,
.mx-n15 {
  margin-right: -15px !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -15px !important; }

.ml-n15,
.mx-n15 {
  margin-left: -15px !important; }

.m-n20 {
  margin: -20px !important; }

.mt-n20,
.my-n20 {
  margin-top: -20px !important; }

.mr-n20,
.mx-n20 {
  margin-right: -20px !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -20px !important; }

.ml-n20,
.mx-n20 {
  margin-left: -20px !important; }

.m-n25 {
  margin: -25px !important; }

.mt-n25,
.my-n25 {
  margin-top: -25px !important; }

.mr-n25,
.mx-n25 {
  margin-right: -25px !important; }

.mb-n25,
.my-n25 {
  margin-bottom: -25px !important; }

.ml-n25,
.mx-n25 {
  margin-left: -25px !important; }

.m-n30 {
  margin: -30px !important; }

.mt-n30,
.my-n30 {
  margin-top: -30px !important; }

.mr-n30,
.mx-n30 {
  margin-right: -30px !important; }

.mb-n30,
.my-n30 {
  margin-bottom: -30px !important; }

.ml-n30,
.mx-n30 {
  margin-left: -30px !important; }

.m-n35 {
  margin: -2.1875rem !important; }

.mt-n35,
.my-n35 {
  margin-top: -2.1875rem !important; }

.mr-n35,
.mx-n35 {
  margin-right: -2.1875rem !important; }

.mb-n35,
.my-n35 {
  margin-bottom: -2.1875rem !important; }

.ml-n35,
.mx-n35 {
  margin-left: -2.1875rem !important; }

.m-n40 {
  margin: -2.5rem !important; }

.mt-n40,
.my-n40 {
  margin-top: -2.5rem !important; }

.mr-n40,
.mx-n40 {
  margin-right: -2.5rem !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -2.5rem !important; }

.ml-n40,
.mx-n40 {
  margin-left: -2.5rem !important; }

.m-n45 {
  margin: -2.8125rem !important; }

.mt-n45,
.my-n45 {
  margin-top: -2.8125rem !important; }

.mr-n45,
.mx-n45 {
  margin-right: -2.8125rem !important; }

.mb-n45,
.my-n45 {
  margin-bottom: -2.8125rem !important; }

.ml-n45,
.mx-n45 {
  margin-left: -2.8125rem !important; }

.m-n50 {
  margin: -3.125rem !important; }

.mt-n50,
.my-n50 {
  margin-top: -3.125rem !important; }

.mr-n50,
.mx-n50 {
  margin-right: -3.125rem !important; }

.mb-n50,
.my-n50 {
  margin-bottom: -3.125rem !important; }

.ml-n50,
.mx-n50 {
  margin-left: -3.125rem !important; }

.m-n60 {
  margin: -3.75rem !important; }

.mt-n60,
.my-n60 {
  margin-top: -3.75rem !important; }

.mr-n60,
.mx-n60 {
  margin-right: -3.75rem !important; }

.mb-n60,
.my-n60 {
  margin-bottom: -3.75rem !important; }

.ml-n60,
.mx-n60 {
  margin-left: -3.75rem !important; }

.m-n70 {
  margin: -4.375rem !important; }

.mt-n70,
.my-n70 {
  margin-top: -4.375rem !important; }

.mr-n70,
.mx-n70 {
  margin-right: -4.375rem !important; }

.mb-n70,
.my-n70 {
  margin-bottom: -4.375rem !important; }

.ml-n70,
.mx-n70 {
  margin-left: -4.375rem !important; }

.m-auto, .owl-prev,
.owl-next {
  margin: auto !important; }

.mt-auto,
.my-auto,
.owl-prev:before,
.owl-prev:after,
.owl-next:before,
.owl-next:after,
.lb-prev,
.lb-next,
.lb-prev:before,
.lb-prev:after,
.lb-next:before,
.lb-next:after,
.lb-close:before,
.lb-close:after,
.image-overlay:after,
.slider__bg img,
.gallery__box img,
.gallery__box a:hover:after,
.gallery__filter:after {
  margin-top: auto !important; }

.mr-auto,
.mx-auto,
.owl-prev:before,
.owl-prev:after,
.owl-next:before,
.owl-next:after,
.lb-outerContainer,
.lb-prev:before,
.lb-prev:after,
.lb-next:before,
.lb-next:after,
.lb-dataContainer,
.lb-close:before,
.lb-close:after,
img.aligncenter,
.image-overlay:after,
.slider__bg img,
.services__box:after,
.gallery__box img,
.gallery__box a:hover:after {
  margin-right: auto !important; }

.mb-auto,
.my-auto,
.owl-prev:before,
.owl-prev:after,
.owl-next:before,
.owl-next:after,
.lb-prev,
.lb-next,
.lb-prev:before,
.lb-prev:after,
.lb-next:before,
.lb-next:after,
.lb-close:before,
.lb-close:after,
.image-overlay:after,
.slider__bg img,
.gallery__box img,
.gallery__box a:hover:after,
.gallery__filter:after {
  margin-bottom: auto !important; }

.ml-auto, .main-menu .menu-item-has-children > a:after,
.mx-auto,
.owl-prev:before,
.owl-prev:after,
.owl-next:before,
.owl-next:after,
.lb-outerContainer,
.lb-prev:before,
.lb-prev:after,
.lb-next:before,
.lb-next:after,
.lb-dataContainer,
.lb-close:before,
.lb-close:after,
img.aligncenter,
.image-overlay:after,
.slider__bg img,
.services__box:after,
.gallery__box img,
.gallery__box a:hover:after {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .m-sm-grid-half {
    margin: 15px !important; }
  .mt-sm-grid-half,
  .my-sm-grid-half {
    margin-top: 15px !important; }
  .mr-sm-grid-half,
  .mx-sm-grid-half {
    margin-right: 15px !important; }
  .mb-sm-grid-half,
  .my-sm-grid-half {
    margin-bottom: 15px !important; }
  .ml-sm-grid-half,
  .mx-sm-grid-half {
    margin-left: 15px !important; }
  .m-sm-grid {
    margin: 30px !important; }
  .mt-sm-grid,
  .my-sm-grid {
    margin-top: 30px !important; }
  .mr-sm-grid,
  .mx-sm-grid {
    margin-right: 30px !important; }
  .mb-sm-grid,
  .my-sm-grid {
    margin-bottom: 30px !important; }
  .ml-sm-grid,
  .mx-sm-grid {
    margin-left: 30px !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important; }
  .m-sm-8 {
    margin: 8px !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8px !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8px !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8px !important; }
  .ml-sm-8, .main-menu .menu-item-has-children > a:after,
  .mx-sm-8 {
    margin-left: 8px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 2.1875rem !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 2.1875rem !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 2.1875rem !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 2.1875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-45 {
    margin: 2.8125rem !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 2.8125rem !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 2.8125rem !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 2.8125rem !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 2.8125rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .m-sm-70 {
    margin: 4.375rem !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 4.375rem !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 4.375rem !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 4.375rem !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 4.375rem !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .p-sm-grid-half {
    padding: 15px !important; }
  .pt-sm-grid-half,
  .py-sm-grid-half {
    padding-top: 15px !important; }
  .pr-sm-grid-half,
  .px-sm-grid-half {
    padding-right: 15px !important; }
  .pb-sm-grid-half,
  .py-sm-grid-half {
    padding-bottom: 15px !important; }
  .pl-sm-grid-half,
  .px-sm-grid-half {
    padding-left: 15px !important; }
  .p-sm-grid {
    padding: 30px !important; }
  .pt-sm-grid,
  .py-sm-grid {
    padding-top: 30px !important; }
  .pr-sm-grid,
  .px-sm-grid {
    padding-right: 30px !important; }
  .pb-sm-grid,
  .py-sm-grid {
    padding-bottom: 30px !important; }
  .pl-sm-grid,
  .px-sm-grid {
    padding-left: 30px !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important; }
  .p-sm-8 {
    padding: 8px !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8px !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8px !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8px !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8px !important; }
  .p-sm-10, .main-menu a {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20,
  .main-menu > li > a {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20,
  .main-menu > li > a {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 2.1875rem !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 2.1875rem !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 2.1875rem !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 2.1875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-45 {
    padding: 2.8125rem !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 2.8125rem !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 2.8125rem !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 2.8125rem !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 2.8125rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .p-sm-70 {
    padding: 4.375rem !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 4.375rem !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 4.375rem !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 4.375rem !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 4.375rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .m-sm-ngrid-half {
    margin: -15px !important; }
  .mt-sm-ngrid-half,
  .my-sm-ngrid-half {
    margin-top: -15px !important; }
  .mr-sm-ngrid-half,
  .mx-sm-ngrid-half {
    margin-right: -15px !important; }
  .mb-sm-ngrid-half,
  .my-sm-ngrid-half {
    margin-bottom: -15px !important; }
  .ml-sm-ngrid-half,
  .mx-sm-ngrid-half {
    margin-left: -15px !important; }
  .m-sm-ngrid {
    margin: -30px !important; }
  .mt-sm-ngrid,
  .my-sm-ngrid {
    margin-top: -30px !important; }
  .mr-sm-ngrid,
  .mx-sm-ngrid {
    margin-right: -30px !important; }
  .mb-sm-ngrid,
  .my-sm-ngrid {
    margin-bottom: -30px !important; }
  .ml-sm-ngrid,
  .mx-sm-ngrid {
    margin-left: -30px !important; }
  .m-sm-n5 {
    margin: -5px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5px !important; }
  .m-sm-n8 {
    margin: -8px !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8px !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8px !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8px !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8px !important; }
  .m-sm-n10 {
    margin: -10px !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10px !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10px !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10px !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10px !important; }
  .m-sm-n15 {
    margin: -15px !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15px !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15px !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15px !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15px !important; }
  .m-sm-n20 {
    margin: -20px !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -20px !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -20px !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -20px !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -20px !important; }
  .m-sm-n25 {
    margin: -25px !important; }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -25px !important; }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -25px !important; }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -25px !important; }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -25px !important; }
  .m-sm-n30 {
    margin: -30px !important; }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -30px !important; }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -30px !important; }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -30px !important; }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -30px !important; }
  .m-sm-n35 {
    margin: -2.1875rem !important; }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -2.1875rem !important; }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -2.1875rem !important; }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -2.1875rem !important; }
  .m-sm-n40 {
    margin: -2.5rem !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -2.5rem !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -2.5rem !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -2.5rem !important; }
  .m-sm-n45 {
    margin: -2.8125rem !important; }
  .mt-sm-n45,
  .my-sm-n45 {
    margin-top: -2.8125rem !important; }
  .mr-sm-n45,
  .mx-sm-n45 {
    margin-right: -2.8125rem !important; }
  .mb-sm-n45,
  .my-sm-n45 {
    margin-bottom: -2.8125rem !important; }
  .ml-sm-n45,
  .mx-sm-n45 {
    margin-left: -2.8125rem !important; }
  .m-sm-n50 {
    margin: -3.125rem !important; }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -3.125rem !important; }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -3.125rem !important; }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -3.125rem !important; }
  .m-sm-n60 {
    margin: -3.75rem !important; }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -3.75rem !important; }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -3.75rem !important; }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -3.75rem !important; }
  .m-sm-n70 {
    margin: -4.375rem !important; }
  .mt-sm-n70,
  .my-sm-n70 {
    margin-top: -4.375rem !important; }
  .mr-sm-n70,
  .mx-sm-n70 {
    margin-right: -4.375rem !important; }
  .mb-sm-n70,
  .my-sm-n70 {
    margin-bottom: -4.375rem !important; }
  .ml-sm-n70,
  .mx-sm-n70 {
    margin-left: -4.375rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-grid-half {
    margin: 15px !important; }
  .mt-md-grid-half,
  .my-md-grid-half {
    margin-top: 15px !important; }
  .mr-md-grid-half,
  .mx-md-grid-half {
    margin-right: 15px !important; }
  .mb-md-grid-half,
  .my-md-grid-half {
    margin-bottom: 15px !important; }
  .ml-md-grid-half,
  .mx-md-grid-half {
    margin-left: 15px !important; }
  .m-md-grid {
    margin: 30px !important; }
  .mt-md-grid,
  .my-md-grid {
    margin-top: 30px !important; }
  .mr-md-grid,
  .mx-md-grid {
    margin-right: 30px !important; }
  .mb-md-grid,
  .my-md-grid {
    margin-bottom: 30px !important; }
  .ml-md-grid,
  .mx-md-grid {
    margin-left: 30px !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important; }
  .m-md-8 {
    margin: 8px !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8px !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8px !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8px !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15, .lb-prev, .main-menu > li:not(:last-child),
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .lb-next,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 2.1875rem !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 2.1875rem !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 2.1875rem !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 2.1875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-45 {
    margin: 2.8125rem !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 2.8125rem !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 2.8125rem !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 2.8125rem !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 2.8125rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .m-md-70 {
    margin: 4.375rem !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 4.375rem !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 4.375rem !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 4.375rem !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 4.375rem !important; }
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .p-md-grid-half {
    padding: 15px !important; }
  .pt-md-grid-half,
  .py-md-grid-half {
    padding-top: 15px !important; }
  .pr-md-grid-half,
  .px-md-grid-half {
    padding-right: 15px !important; }
  .pb-md-grid-half,
  .py-md-grid-half {
    padding-bottom: 15px !important; }
  .pl-md-grid-half,
  .px-md-grid-half {
    padding-left: 15px !important; }
  .p-md-grid {
    padding: 30px !important; }
  .pt-md-grid,
  .py-md-grid {
    padding-top: 30px !important; }
  .pr-md-grid,
  .px-md-grid {
    padding-right: 30px !important; }
  .pb-md-grid,
  .py-md-grid {
    padding-bottom: 30px !important; }
  .pl-md-grid,
  .px-md-grid {
    padding-left: 30px !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important; }
  .p-md-8 {
    padding: 8px !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8px !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8px !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8px !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15,
  .main-menu a {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15,
  .main-menu a {
    padding-left: 15px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 2.1875rem !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 2.1875rem !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 2.1875rem !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 2.1875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-45 {
    padding: 2.8125rem !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 2.8125rem !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 2.8125rem !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 2.8125rem !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 2.8125rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .p-md-70 {
    padding: 4.375rem !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 4.375rem !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 4.375rem !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 4.375rem !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 4.375rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .m-md-ngrid-half {
    margin: -15px !important; }
  .mt-md-ngrid-half,
  .my-md-ngrid-half {
    margin-top: -15px !important; }
  .mr-md-ngrid-half,
  .mx-md-ngrid-half {
    margin-right: -15px !important; }
  .mb-md-ngrid-half,
  .my-md-ngrid-half {
    margin-bottom: -15px !important; }
  .ml-md-ngrid-half,
  .mx-md-ngrid-half {
    margin-left: -15px !important; }
  .m-md-ngrid {
    margin: -30px !important; }
  .mt-md-ngrid,
  .my-md-ngrid {
    margin-top: -30px !important; }
  .mr-md-ngrid,
  .mx-md-ngrid {
    margin-right: -30px !important; }
  .mb-md-ngrid,
  .my-md-ngrid {
    margin-bottom: -30px !important; }
  .ml-md-ngrid,
  .mx-md-ngrid {
    margin-left: -30px !important; }
  .m-md-n5 {
    margin: -5px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5px !important; }
  .m-md-n8 {
    margin: -8px !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8px !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8px !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8px !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8px !important; }
  .m-md-n10 {
    margin: -10px !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10px !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10px !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10px !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10px !important; }
  .m-md-n15 {
    margin: -15px !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15px !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15px !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15px !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15px !important; }
  .m-md-n20 {
    margin: -20px !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -20px !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -20px !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -20px !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -20px !important; }
  .m-md-n25 {
    margin: -25px !important; }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -25px !important; }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -25px !important; }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -25px !important; }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -25px !important; }
  .m-md-n30 {
    margin: -30px !important; }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -30px !important; }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -30px !important; }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -30px !important; }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -30px !important; }
  .m-md-n35 {
    margin: -2.1875rem !important; }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -2.1875rem !important; }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -2.1875rem !important; }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -2.1875rem !important; }
  .m-md-n40 {
    margin: -2.5rem !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -2.5rem !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -2.5rem !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -2.5rem !important; }
  .m-md-n45 {
    margin: -2.8125rem !important; }
  .mt-md-n45,
  .my-md-n45 {
    margin-top: -2.8125rem !important; }
  .mr-md-n45,
  .mx-md-n45 {
    margin-right: -2.8125rem !important; }
  .mb-md-n45,
  .my-md-n45 {
    margin-bottom: -2.8125rem !important; }
  .ml-md-n45,
  .mx-md-n45 {
    margin-left: -2.8125rem !important; }
  .m-md-n50 {
    margin: -3.125rem !important; }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -3.125rem !important; }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -3.125rem !important; }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -3.125rem !important; }
  .m-md-n60 {
    margin: -3.75rem !important; }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -3.75rem !important; }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -3.75rem !important; }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -3.75rem !important; }
  .m-md-n70 {
    margin: -4.375rem !important; }
  .mt-md-n70,
  .my-md-n70 {
    margin-top: -4.375rem !important; }
  .mr-md-n70,
  .mx-md-n70 {
    margin-right: -4.375rem !important; }
  .mb-md-n70,
  .my-md-n70 {
    margin-bottom: -4.375rem !important; }
  .ml-md-n70,
  .mx-md-n70 {
    margin-left: -4.375rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1230px) {
  .m-lg-grid-half {
    margin: 15px !important; }
  .mt-lg-grid-half,
  .my-lg-grid-half {
    margin-top: 15px !important; }
  .mr-lg-grid-half,
  .mx-lg-grid-half {
    margin-right: 15px !important; }
  .mb-lg-grid-half,
  .my-lg-grid-half {
    margin-bottom: 15px !important; }
  .ml-lg-grid-half,
  .mx-lg-grid-half {
    margin-left: 15px !important; }
  .m-lg-grid {
    margin: 30px !important; }
  .mt-lg-grid,
  .my-lg-grid {
    margin-top: 30px !important; }
  .mr-lg-grid,
  .mx-lg-grid {
    margin-right: 30px !important; }
  .mb-lg-grid,
  .my-lg-grid {
    margin-bottom: 30px !important; }
  .ml-lg-grid,
  .mx-lg-grid {
    margin-left: 30px !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important; }
  .m-lg-8 {
    margin: 8px !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8px !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8px !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8px !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 2.1875rem !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 2.1875rem !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 2.1875rem !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 2.1875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-45 {
    margin: 2.8125rem !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 2.8125rem !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 2.8125rem !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 2.8125rem !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 2.8125rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .m-lg-70 {
    margin: 4.375rem !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 4.375rem !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 4.375rem !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 4.375rem !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 4.375rem !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .p-lg-grid-half {
    padding: 15px !important; }
  .pt-lg-grid-half,
  .py-lg-grid-half {
    padding-top: 15px !important; }
  .pr-lg-grid-half,
  .px-lg-grid-half {
    padding-right: 15px !important; }
  .pb-lg-grid-half,
  .py-lg-grid-half {
    padding-bottom: 15px !important; }
  .pl-lg-grid-half,
  .px-lg-grid-half {
    padding-left: 15px !important; }
  .p-lg-grid {
    padding: 30px !important; }
  .pt-lg-grid,
  .py-lg-grid {
    padding-top: 30px !important; }
  .pr-lg-grid,
  .px-lg-grid {
    padding-right: 30px !important; }
  .pb-lg-grid,
  .py-lg-grid {
    padding-bottom: 30px !important; }
  .pl-lg-grid,
  .px-lg-grid {
    padding-left: 30px !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important; }
  .p-lg-8 {
    padding: 8px !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8px !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8px !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8px !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 2.1875rem !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 2.1875rem !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 2.1875rem !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 2.1875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-45 {
    padding: 2.8125rem !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 2.8125rem !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 2.8125rem !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 2.8125rem !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 2.8125rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .p-lg-70 {
    padding: 4.375rem !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 4.375rem !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 4.375rem !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 4.375rem !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 4.375rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .m-lg-ngrid-half {
    margin: -15px !important; }
  .mt-lg-ngrid-half,
  .my-lg-ngrid-half {
    margin-top: -15px !important; }
  .mr-lg-ngrid-half,
  .mx-lg-ngrid-half {
    margin-right: -15px !important; }
  .mb-lg-ngrid-half,
  .my-lg-ngrid-half {
    margin-bottom: -15px !important; }
  .ml-lg-ngrid-half,
  .mx-lg-ngrid-half {
    margin-left: -15px !important; }
  .m-lg-ngrid {
    margin: -30px !important; }
  .mt-lg-ngrid,
  .my-lg-ngrid {
    margin-top: -30px !important; }
  .mr-lg-ngrid,
  .mx-lg-ngrid {
    margin-right: -30px !important; }
  .mb-lg-ngrid,
  .my-lg-ngrid {
    margin-bottom: -30px !important; }
  .ml-lg-ngrid,
  .mx-lg-ngrid {
    margin-left: -30px !important; }
  .m-lg-n5 {
    margin: -5px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5px !important; }
  .m-lg-n8 {
    margin: -8px !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8px !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8px !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8px !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8px !important; }
  .m-lg-n10 {
    margin: -10px !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10px !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10px !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10px !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10px !important; }
  .m-lg-n15 {
    margin: -15px !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15px !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15px !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15px !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15px !important; }
  .m-lg-n20 {
    margin: -20px !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -20px !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -20px !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -20px !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -20px !important; }
  .m-lg-n25 {
    margin: -25px !important; }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -25px !important; }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -25px !important; }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -25px !important; }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -25px !important; }
  .m-lg-n30 {
    margin: -30px !important; }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -30px !important; }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -30px !important; }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -30px !important; }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -30px !important; }
  .m-lg-n35 {
    margin: -2.1875rem !important; }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -2.1875rem !important; }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -2.1875rem !important; }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -2.1875rem !important; }
  .m-lg-n40 {
    margin: -2.5rem !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -2.5rem !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -2.5rem !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -2.5rem !important; }
  .m-lg-n45 {
    margin: -2.8125rem !important; }
  .mt-lg-n45,
  .my-lg-n45 {
    margin-top: -2.8125rem !important; }
  .mr-lg-n45,
  .mx-lg-n45 {
    margin-right: -2.8125rem !important; }
  .mb-lg-n45,
  .my-lg-n45 {
    margin-bottom: -2.8125rem !important; }
  .ml-lg-n45,
  .mx-lg-n45 {
    margin-left: -2.8125rem !important; }
  .m-lg-n50 {
    margin: -3.125rem !important; }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -3.125rem !important; }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -3.125rem !important; }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -3.125rem !important; }
  .m-lg-n60 {
    margin: -3.75rem !important; }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -3.75rem !important; }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -3.75rem !important; }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -3.75rem !important; }
  .m-lg-n70 {
    margin: -4.375rem !important; }
  .mt-lg-n70,
  .my-lg-n70 {
    margin-top: -4.375rem !important; }
  .mr-lg-n70,
  .mx-lg-n70 {
    margin-right: -4.375rem !important; }
  .mb-lg-n70,
  .my-lg-n70 {
    margin-bottom: -4.375rem !important; }
  .ml-lg-n70,
  .mx-lg-n70 {
    margin-left: -4.375rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right, .lb-dataContainer {
  text-align: right !important; }

.text-center, .owl-dots {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1230px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase, .main-menu > li > a {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold, .entry-content ul {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white, .offices__btn:not(.active) {
  color: #fff !important; }

.text-body {
  color: #666666 !important; }

.text-muted {
  color: #A7A7A7 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  -webkit-tap-highlight-color: transparent;
  z-index: 1; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }

.owl-stage {
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  backface-visibility: hidden; }

.owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-stage-outer {
  overflow: hidden;
  transform: translate3d(0, 0, 0); }

.owl-wrapper,
.owl-item {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0); }

.owl-item {
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-prev,
.owl-next,
.owl-dot {
  cursor: pointer;
  user-select: none; }

@media (min-width: 768px) {
  .owl-dots {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; } }

.owl-dot {
  transform: rotate(45deg); }
  @media (min-width: 768px) {
    .owl-dot {
      width: 15px;
      height: 15px; } }
  @media (max-width: 767.98px) {
    .owl-dot {
      width: 10px;
      height: 10px; } }
  .owl-dot:not(:hover):not(.active) {
    opacity: .7; }
  .owl-dot:not(.active) {
    background-image: linear-gradient(180deg, #FFDA01, #FFA43C);
    background-repeat: repeat-x; }
  .owl-dot.active {
    background-image: linear-gradient(180deg, #beef01, #6ABD45);
    background-repeat: repeat-x; }

.owl-prev,
.owl-next {
  top: 0;
  bottom: 47px;
  width: 60px;
  height: 60px;
  border: 2px solid #fff; }
  .owl-prev:before, .owl-prev:after,
  .owl-next:before,
  .owl-next:after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 16px;
    height: 2px; }
  .owl-prev:hover,
  .owl-next:hover {
    background-color: rgba(255, 218, 1, 0.3);
    border-color: #FFDA01; }

.owl-prev {
  left: 15px; }
  .owl-prev:before {
    transform: translate(-1px, 5px) rotate(45deg); }
  .owl-prev:after {
    transform: translate(-1px, -5px) rotate(-45deg); }

.owl-next {
  right: 15px; }
  .owl-next:before {
    transform: translate(1px, -5px) rotate(45deg); }
  .owl-next:after {
    transform: translate(1px, 5px) rotate(-45deg); }

.owl-height {
  transition: height 500ms ease-in-out; }

.lightboxOverlay {
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  opacity: .65;
  display: none; }

.lightbox {
  left: 0;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  box-shadow: 0 4px 36px rgba(0, 0, 0, 0.29); }

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-prev,
.lb-next {
  top: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #fff; }
  .lb-prev:before, .lb-prev:after,
  .lb-next:before,
  .lb-next:after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 10px;
    height: 2px;
    transition: 250ms ease-in-out;
    background-color: #fff; }
  @media (min-width: 768px) {
    .lb-prev:hover,
    .lb-next:hover {
      border-color: #6ABD45; }
      .lb-prev:hover:before, .lb-prev:hover:after,
      .lb-next:hover:before,
      .lb-next:hover:after {
        background-color: #6ABD45; } }

@media (min-width: 992px) {
  .lb-prev {
    right: 100%; } }

@media (max-width: 991.98px) {
  .lb-prev {
    left: 0; } }

.lb-prev:before {
  transform: translate(0, 3px) rotate(45deg); }

.lb-prev:after {
  transform: translate(0, -3px) rotate(-45deg); }

@media (min-width: 992px) {
  .lb-next {
    left: 100%; } }

@media (max-width: 991.98px) {
  .lb-next {
    right: 0; } }

.lb-next:before {
  transform: translate(0, -3px) rotate(45deg); }

.lb-next:after {
  transform: translate(0, 3px) rotate(-45deg); }

.lb-dataContainer {
  bottom: 100%;
  left: 0;
  right: 0;
  *zoom: 1;
  width: 100%;
  opacity: 1 !important; }

.lb-close {
  width: 30px;
  height: 30px; }
  .lb-close:before, .lb-close:after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 18px;
    height: 2px; }
  .lb-close:before {
    transform: rotate(45deg); }
  .lb-close:after {
    transform: rotate(-45deg); }
  .lb-close:hover:before, .lb-close:hover:after {
    background-color: #6ABD45; }

h1, .h1 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h1, .h1 {
  font-size: 2.625rem; }

h2, .h2 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  margin-bottom: 0;
  font-family: Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #333333; }

h6, .h6 {
  font-size: 15px; }

.font-size-base {
  font-size: 15px !important; }

.font-size-lg {
  font-size: 18px !important; }

.font-size-sm {
  font-size: 14px !important; }

@media (min-width: 768px) {
  .font-size-base-sm {
    font-size: 15px !important; }
  .font-size-lg-sm {
    font-size: 18px !important; }
  .font-size-sm-sm {
    font-size: 14px !important; } }

@media (min-width: 992px) {
  .font-size-base-md {
    font-size: 15px !important; }
  .font-size-lg-md {
    font-size: 18px !important; }
  .font-size-sm-md {
    font-size: 14px !important; } }

@media (min-width: 1230px) {
  .font-size-base-lg {
    font-size: 15px !important; }
  .font-size-lg-lg {
    font-size: 18px !important; }
  .font-size-sm-lg {
    font-size: 14px !important; } }

.line-height-0 {
  line-height: 0 !important; }

.line-height-base {
  line-height: 1 !important; }

.text-black {
  color: #000 !important; }

a.text-black:hover, a.text-black:focus,
button.text-black:hover,
button.text-black:focus {
  color: black !important;
  text-decoration: none; }

.text-gray-200 {
  color: #F8F8F8 !important; }

a.text-gray-200:hover, a.text-gray-200:focus,
button.text-gray-200:hover,
button.text-gray-200:focus {
  color: #ebebeb !important;
  text-decoration: none; }

.text-gray-800, .offices__btn.active {
  color: #333333 !important; }

a.text-gray-800:hover, a.offices__btn.active:hover, a.text-gray-800:focus, a.offices__btn.active:focus,
button.text-gray-800:hover,
button.offices__btn.active:hover,
button.text-gray-800:focus,
button.offices__btn.active:focus {
  color: #262626 !important;
  text-decoration: none; }

.text-yellow {
  color: #FFDA01 !important; }

a.text-yellow:hover, a.text-yellow:focus,
button.text-yellow:hover,
button.text-yellow:focus {
  color: #e7c500 !important;
  text-decoration: none; }

.text-green, .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  color: #6ABD45 !important; }

a.text-green:hover, a.text-green:focus,
button.text-green:hover,
button.text-green:focus {
  color: #5fac3d !important;
  text-decoration: none; }

.text-white, .offices__btn:not(.active) {
  color: #fff !important; }

a.text-white:hover, a.offices__btn:hover:not(.active), a.text-white:focus, a.offices__btn:focus:not(.active),
button.text-white:hover,
button.offices__btn:hover:not(.active),
button.text-white:focus,
button.offices__btn:focus:not(.active) {
  color: #f2f2f2 !important;
  text-decoration: none; }

.text-inherit {
  color: inherit !important; }

.text-shadow {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.17) !important; }

.font-weight-medium {
  font-weight: 500 !important; }

p {
  line-height: 1.625; }
  p > a {
    color: #6ABD45; }
    p > a:hover {
      color: #6ABD45;
      text-decoration: underline; }

.entry-content {
  line-height: 1.625; }
  .entry-content ul li {
    margin-bottom: 10px;
    padding-left: 35px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 20 20'%3e%3cpath fill='%236ABD45' d='M10,3 C4.48577883,3 0,7.48577883 0,13 C0,18.5142212 4.48577883,23 10,23 C15.5142212,23 20,18.5142212 20,13 C20,7.48577883 15.5142212,3 10,3 Z M15.0683594,10.8808594 L9.65164184,16.2974243 C9.48913574,16.4599304 9.27581789,16.5417175 9.0625,16.5417175 C8.84918211,16.5417175 8.63586426,16.4599304 8.47335816,16.2974243 L5.7650757,13.5891418 C5.43914797,13.2633667 5.43914797,12.7366333 5.7650757,12.4108582 C6.09085082,12.0849304 6.61743164,12.0849304 6.94335937,12.4108582 L9.0625,14.5299988 L13.8900757,9.7025757 C14.2158508,9.37664797 14.7424316,9.37664797 15.0683594,9.7025757 C15.3941345,10.0283508 15.3941345,10.5549316 15.0683594,10.8808594 Z'/%3e%3c/svg%3e") no-repeat 0 0/20px 20px; }
  .entry-content img {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.19); }

@media (min-width: 992px) and (max-width: 1229.98px) {
  html {
    font-size: 13px; }
  body {
    font-size: 15px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  html {
    font-size: 11px; }
  body {
    font-size: 14px; } }

@media (max-width: 767.98px) {
  html {
    font-size: 9px; }
  body {
    font-size: 13px; } }

.btn {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  background: transparent;
  min-width: 150px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
  height: 50px;
  padding: 0 0.75rem;
  font-size: 16px;
  line-height: 1;
  border-radius: 0.25rem; }
  @media (max-width: 767.98px) {
    .btn {
      height: 45px; } }
  :not(button).btn {
    cursor: pointer; }
  .btn--sm {
    height: 40px;
    padding: 0 0.75rem;
    font-size: 16px;
    line-height: 1;
    border-radius: 0.25rem; }
    @media (max-width: 767.98px) {
      .btn--sm {
        height: 35px; } }
  @media (min-width: 768px) {
    .btn--200 {
      min-width: 200px; } }
  .btn--play {
    top: 0;
    left: 0;
    font-size: 22px; }
    .btn--play:hover {
      background-color: rgba(106, 189, 69, 0.2); }
  .btn-menu {
    position: relative;
    width: 30px;
    height: 30px;
    transition: none; }
    .btn-menu:before, .btn-menu:after {
      content: ''; }
    .btn-menu span, .btn-menu:before, .btn-menu:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
      width: 30px;
      height: 4px;
      background-color: #6ABD45;
      border-radius: 2px;
      transition: all 250ms ease; }
    .btn-menu:before {
      transform: translate(0, -8px); }
    .btn-menu:after {
      transform: translate(0, 8px); }
    .btn-menu[aria-expanded="true"] span,
    .menu-opened .btn-menu span {
      opacity: 0; }
    .btn-menu[aria-expanded="true"]:before, .btn-menu[aria-expanded="true"]:after,
    .menu-opened .btn-menu:before,
    .menu-opened .btn-menu:after {
      background-color: #FFDA01; }
    .btn-menu[aria-expanded="true"]:before,
    .menu-opened .btn-menu:before {
      transform: rotate(45deg) translate(0, 0); }
    .btn-menu[aria-expanded="true"]:after,
    .menu-opened .btn-menu:after {
      transform: rotate(-45deg) translate(0, 0); }

.btn--black {
  color: #fff;
  background-image: linear-gradient(to bottom, #666666 0%, #000 100%);
  background-repeat: repeat-x; }
  .btn--black:hover {
    color: #fff;
    background-image: linear-gradient(to bottom, #000 0%, #000 100%);
    background-repeat: repeat-x; }

.btn--green {
  color: #fff;
  background-image: linear-gradient(to bottom, #beef01 0%, #6ABD45 100%);
  background-repeat: repeat-x; }
  .btn--green:hover {
    color: #fff;
    background-image: linear-gradient(to bottom, #6ABD45 0%, #6ABD45 100%);
    background-repeat: repeat-x; }

.btn--yellow {
  color: #fff;
  background-image: linear-gradient(to bottom, #FFDA01 0%, #FFA43C 100%);
  background-repeat: repeat-x; }
  .btn--yellow:hover {
    color: #fff;
    background-image: linear-gradient(to bottom, #FFA43C 0%, #FFA43C 100%);
    background-repeat: repeat-x; }

.btn--outline-yellow {
  color: #fff;
  background-color: rgba(255, 218, 1, 0.5);
  background-image: none;
  border: 2px solid #FFDA01; }
  .btn--outline-yellow:hover {
    color: #fff;
    background-color: rgba(255, 218, 1, 0.8); }

.form-control {
  display: block;
  width: 100%;
  height: 50px;
  padding: 15px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  color: #333333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #6ABD45;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control::placeholder {
    padding-top: 0px;
    color: #999999;
    font-weight: 300;
    opacity: 1; }
  .form-control:focus {
    box-shadow: 0 0 7px rgba(106, 189, 69, 0.65); }

select.form-control:focus::-ms-value {
  color: #333333;
  background-color: #fff; }

textarea.form-control {
  height: 264px; }

.form-group {
  margin-bottom: 30px; }

main {
  padding-top: 54px; }

.main-menu a:hover {
  color: #FFDA01; }

@media (max-width: 767.98px) {
  .main-menu > li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); } }

@media (min-width: 768px) {
  .main-menu .menu-item-has-children:hover > .sub-menu {
    display: block; } }

@media (max-width: 767.98px) {
  .main-menu .menu-item-has-children.sub-menu-opened > .sub-menu {
    display: block; } }

.main-menu .menu-item-has-children > a:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 4px solid #6ABD45;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: 250ms ease-in-out; }

.main-menu .menu-item-has-children > a:hover:after {
  border-top-color: #FFDA01; }

.main-menu .sub-menu {
  display: none; }
  @media (min-width: 768px) {
    .main-menu .sub-menu {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 5px 0;
      z-index: 2;
      white-space: nowrap;
      background: #fff;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05); } }

@media (max-width: 767.98px) {
  #menu {
    transition: none !important;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    bottom: 0;
    background-color: #fff; } }

.page-header {
  min-height: 21.875rem;
  background: no-repeat center/cover;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }

@media (min-width: 992px) {
  .image-overlay:after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to right, transparent 65%, #fff 100%);
    background-repeat: repeat-x; } }

@media (min-width: 1230px) {
  .image-overlay {
    width: 670px; } }

@media (min-width: 992px) and (max-width: 1229.98px) {
  .image-overlay {
    width: 130%; } }

@media (min-width: 992px) {
  .order-md-2 .image-overlay:after {
    background-image: linear-gradient(to right, #fff 0, transparent 35%);
    background-repeat: repeat-x; } }

.slider:not(.owl-loaded) {
  display: flex;
  overflow: hidden; }

:not(.owl-loaded) .slider .item {
  max-width: 100%;
  flex: 0 0 100%; }

@media (min-width: 992px) {
  .slider .item {
    min-height: 500px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .slider .item {
    min-height: 300px; } }

@media (min-width: 992px) {
  .slider .item img[src*="tractor"] {
    max-width: none;
    position: relative;
    bottom: -26px;
    left: -25%; } }

@media (min-width: 768px) {
  .slider .item img[src*="tractor"] {
    max-width: 180%;
    position: relative;
    bottom: -26px;
    left: -25%; } }

.slider__bg img {
  top: 0;
  bottom: 0;
  left: -9999px;
  right: -9999px;
  height: 100%;
  width: auto;
  max-width: none; }

.crops {
  background: url("../images/backgrounds/helga-crops-bg.svg") calc(50% - 30px) center no-repeat; }
  .crops__box {
    transition: 250ms ease-in-out;
    box-shadow: 0 2px 17px rgba(0, 0, 0, 0.16); }
    @media (min-width: 768px) {
      .crops__box:hover {
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
        transform: translate(0, -15px); } }
    @media (max-width: 767.98px) {
      .crops__box img {
        min-width: 100%; } }

.about {
  background-image: linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
  background-repeat: repeat-x; }
  .about__poster img {
    min-width: 100%; }

.services__box {
  height: 0;
  color: #fff;
  padding-bottom: 78.125%;
  border: 1px solid #fff;
  background: no-repeat center/cover; }
  .services__box:after {
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);
    background-repeat: repeat-x;
    transition: 250ms ease-in-out; }
  .services__box:hover {
    color: #FFDA01; }
    .services__box:hover:after {
      height: 200px; }
  @media (min-width: 768px) {
    .services__box--lg {
      padding-bottom: 52.0833333333%; } }
  .services__box__content {
    top: 0;
    left: 0; }

.offices__btn {
  height: 3.75rem;
  font-size: 1.25rem;
  margin-bottom: -2px; }
  @media (min-width: 768px) {
    .offices__btn {
      width: 15rem; } }
  .offices__btn:first-child {
    border-top-left-radius: 8px; }
    .offices__btn:first-child:not(:last-child).active {
      box-shadow: inset 0 2px 0 #6ABD45, inset 2px 0 0 #6ABD45, 0 -5px 20px rgba(106, 189, 69, 0.18); }
    .offices__btn:first-child:last-child.active {
      box-shadow: inset 0 2px 0 #6ABD45, inset 2px 0 0 #6ABD45, inset -2px 0 0 #6ABD45, 0 -5px 20px rgba(106, 189, 69, 0.18); }
  .offices__btn:last-child {
    border-top-right-radius: 8px; }
    .offices__btn:last-child:not(:first-child).active {
      box-shadow: inset 0 2px 0 #6ABD45, inset -2px 0 0 #6ABD45, 0 -5px 20px rgba(106, 189, 69, 0.18); }
  .offices__btn.active {
    background-color: #fff;
    box-shadow: inset 0 2px 0 #6ABD45, 0 -5px 20px rgba(106, 189, 69, 0.18); }

.offices__content {
  border: 2px solid #6ABD45;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; }
  @media (min-width: 768px) {
    .offices__content {
      border-top-right-radius: 8px; } }
  .offices__content a {
    color: inherit; }

@media (max-width: 767.98px) {
  .gallery .col-6:not(:nth-child(-n + 4)) {
    display: none; } }

.gallery__box {
  height: 0;
  padding-bottom: 100%;
  border: 1px solid #fff; }
  .gallery__box img {
    top: 0;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    min-width: 100%;
    max-width: 130%;
    height: 100%;
    width: auto;
    transition: 250ms ease-in-out; }
  .gallery__box a:hover img {
    opacity: .5; }
  .gallery__box a:hover:after {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3e%3cpath fill='%23fff' d='M59.629,59.634 C59.385,59.878 59.065,60.000 58.745,60.000 C58.425,60.000 58.106,59.878 57.862,59.634 L39.607,41.378 C35.397,45.170 29.846,47.500 23.748,47.500 C10.653,47.500 -0.000,36.846 -0.000,23.750 C-0.000,10.654 10.653,0.000 23.748,0.000 C36.843,0.000 47.496,10.654 47.496,23.750 C47.496,29.849 45.166,35.400 41.374,39.610 L59.629,57.866 C60.117,58.354 60.117,59.145 59.629,59.634 ZM44.996,23.750 C44.996,12.032 35.463,2.500 23.748,2.500 C12.033,2.500 2.500,12.033 2.500,23.750 C2.500,35.467 12.033,45.000 23.748,45.000 C35.463,45.000 44.996,35.468 44.996,23.750 ZM25.000,34.000 L23.000,34.000 L23.000,25.000 L14.000,25.000 L14.000,23.000 L23.000,23.000 L23.000,14.000 L25.000,14.000 L25.000,23.000 L34.000,23.000 L34.000,25.000 L25.000,25.000 L25.000,34.000 Z'/%3e%3c/svg%3e") no-repeat center/60px 60px; }

.gallery__filter:after {
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
  background-image: linear-gradient(to right, transparent 0, #fff 70%);
  background-repeat: repeat-x; }

.gallery__filter button {
  height: 30px; }
  @media (min-width: 768px) {
    .gallery__filter button:hover {
      color: #fff;
      background-color: #FFDA01; } }
  .gallery__filter button.current {
    color: #fff;
    background-color: #6ABD45; }

.footer {
  background-color: #111111; }
  .footer__menu {
    line-height: 1.625; }
    .footer__menu a:hover {
      color: #6ABD45;
      text-decoration: underline; }

.copy {
  color: #666;
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
