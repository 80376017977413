.image-overlay {

    @include media-breakpoint-up(md) {

        &:after {
          content: '';
          @include element-center(true);
          @include gradient-x(transparent, $white, 65%);
        }
    }

    @include media-breakpoint-up(lg) {
      width: 670px;
    }

    @include media-breakpoint-only(md) {
      width: 130%;
    }

    .order-md-2 & {
      @extend .float-md-right;

        @include media-breakpoint-up(md) {

            &:after {
              @include gradient-x($white, transparent, 0, 35%);
            }
        }
    }
}