@mixin element-center ( $extend: false, $top: 0, $right: 0, $bottom: 0, $left: 0 ) {

  @if $extend { @extend .position-absolute; }
  @else { position: absolute; }
  @if $top != false { top: $top; }
  @if $bottom != false { bottom: $bottom; }
  @if $left != false { left: $left; }
  @if $right != false { right: $right; }
  @if $extend { 

    @if $top != false and $bottom != false {
      @extend .my-auto;
    }

    @if $left != false and $right != false {
      @extend .mx-auto;
    }
  }
  @else { 

      @if $top != false and $bottom != false {
        margin-top: auto;
        margin-bottom: auto;
      }

      @if $left != false and $right != false {
        margin-left: auto;
        margin-right: auto;
      }
  }
}

@mixin fixed-center ( $extend: false, $top: 0, $right: 0, $bottom: 0, $left: 0 ) {

  @if $extend { @extend .position-fixed; }
  @else { position: fixed; }
  @if $top != false { top: $top; }
  @if $bottom != false { bottom: $bottom; }
  @if $left != false { left: $left; }
  @if $right != false { right: $right; }
  @if $extend { 

    @if $top != false and $bottom != false {
      @extend .my-auto;
    }

    @if $left != false and $right != false {
      @extend .mx-auto;
    }
  }
  @else { 

      @if $top != false and $bottom != false {
        margin-top: auto;
        margin-bottom: auto;
      }

      @if $left != false and $right != false {
        margin-left: auto;
        margin-right: auto;
      }
  }
}